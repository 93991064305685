
function soloNumeros(evt) {
  console.log('soloNumeros')
  var tecla = String.fromCharCode(evt.which || evt.keyCode);
  if (!/[-\d\b\r]/.test(tecla)) return false;
  return true;
}

function verificarNumerosChange(n /* idElement*/) {
  console.log('verificarNumerosChange')
  permitidos = /^-?([0-9]?)+/;
  if (!permitidos.test(n.value)) {
    n.value = "";
    n.focus();
    return false;
  }
  
  return true;
}

function setValuesSrPago(name, email, phone, street, city, zipcode, town,
  number, expiry_month, expiry_year, amount, token, idTransaction, months, isGift) {

  document.getElementById('name_span').innerHTML = name;
  document.getElementById('email_span').innerHTML = email;
  document.getElementById('phone_span').innerHTML = phone;
  document.getElementById('street_span').innerHTML = street;
  document.getElementById('city_span').innerHTML = city;
  document.getElementById('zipcode_span').innerHTML = zipcode;
  document.getElementById('town_span').innerHTML = town;
  document.getElementById('number_span').innerHTML = number;
  document.getElementById('expiry_month_span').innerHTML = expiry_month;
  document.getElementById('expiry_year_span').innerHTML = expiry_year;
  document.getElementById('amount_span').innerHTML = amount;
  document.getElementById('token_span').innerHTML = token;
  document.getElementById('idTransaction_span').innerHTML = idTransaction
  document.getElementById('months_span').innerHTML = months
  document.getElementById('isGift').innerHTML = isGift
  console.log("setValuesTest")
  document.getElementById('cvv').focus();

  document.getElementById('loadPromotions').click();

  return true;
}



function amexFormInitValues(sessionID, idOrder, idTransaction, succesIndicator, isGift) {
  document.getElementById('token_span').innerHTML = sessionID
  document.getElementById('idorder_span').innerHTML = idOrder
  document.getElementById('idtransaction_span').innerHTML = idTransaction
  document.getElementById('succesIndicator_span').innerHTML = succesIndicator
  document.getElementById('isGift').innerHTML = isGift

  var jsonObject = {
    session: {
      id: sessionID
    },
    interaction: {
      merchant: {
        name: 'TEST8482158268'
      },
      operation: 'PURCHASE'
    },
    order:{
      description:'Payment'
    }
  }
  console.log(JSON.stringify(jsonObject))
  Checkout.configure(jsonObject);
  document.getElementById('button').click();
}

function errorCallback(error) {
  console.log("errorCallback")
  console.log(JSON.stringify(error));
  redirectPage('/error')
}
function cancelCallback() {
  console.log("cancelCallback")
  console.log('Payment cancelled');
}
function redirectPage(url){
  console.log(url)
  location.href = url
}


function completeCallback(resultIndicator, sessionVersion) {
  console.log("completeCallback")
  const indicatorArray = resultIndicator.split(",");
  let indicatorResult = indicatorArray[0];
  let succesIndicator = document.getElementById('succesIndicator_span').innerHTML;

  if (succesIndicator == indicatorResult) {
    document.getElementById('detailTransactionBtn').click()

  }
  console.log(resultIndicator)
  console.log(sessionVersion)
}

function dataErrorMessage(error){
  console.log("dataErrorMessage")
  console.log(error)
}



function setPaymentProccesorData(payload){
  console.log(payload)
  document.getElementById('idTransaction_span').innerHTML = payload.idTransaction;
  document.getElementById('idUser_span').innerHTML = payload.idUser;
  document.getElementById('idBeneficiary_span').innerHTML = payload.idBeneficiary;
  document.getElementById('idCard_span').innerHTML = payload.idCard;
  document.getElementById('description_span').innerHTML = payload.description
  document.getElementById('amount_span').innerHTML = payload.amount
  document.getElementById('comission_span').innerHTML = payload.comission
  document.getElementById('latitud_span').innerHTML = payload.latitud
  document.getElementById('longitud_span').innerHTML = payload.longitud
  document.getElementById('cardType_span').innerHTML = payload.cardType
  document.getElementById('email_span').innerHTML = payload.email
  document.getElementById('userName_span').innerHTML = payload.userName
  document.getElementById('beneficiaryEmail_span').innerHTML = payload.beneficiaryEmail
  document.getElementById('isForValidCard_span').innerHTML = payload.isForValidCard
  document.getElementById('number_span').innerHTML = payload.number
  //document.getElementById('expiry_month_span').innerHTML = payload.expiry_month
  //document.getElementById('expiry_year_span').innerHTML = payload.expiry_year
  document.getElementById('expiredData_span').innerHTML = payload.expiredData
  document.getElementById('userEmail_span').innerHTML = payload.userEmail;

  document.getElementById('isGift').innerHTML = payload.isGift
  document.getElementById('setterButton').click();

}


function dataErrorMessage(error){
  console.log("dataErrorMessage")
  console.log(error)
}

